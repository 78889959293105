import React from 'react';
import styled from 'styled-components';


const PresentationWrapper = styled.div`
    position: relative;
    width: 100vw;
    height: 100vh;
`;

const Title = styled.h1`
    position: absolute;
    top: 20vh;
    left: 5vw;
    font-size: clamp(1rem, 6vw + 0.5rem, 8vh);
    --color: #C6ECEE;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
`;

const Subtitle = styled.h2`
    position: absolute;
    top: 40vh;
    left: 5vw;
    font-size: clamp(1rem, 3vw + 0.5rem, 4vh);
    --color: #ACEE4D;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
`;

function Presentation() {
    return (
        <PresentationWrapper>
            <Title>Votos Desperdiçados</Title>
            <Subtitle>Nas legislativas de 2022, 13,48% dos votos foram desperdiçados</Subtitle>
        </PresentationWrapper>
    );
}

export default Presentation;
