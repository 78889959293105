import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import * as d3 from 'd3';
import { sankey, sankeyLinkHorizontal } from 'd3-sankey';
import sankeyData from '../data/sankeyDataset.json';

const SankeyDiagramWrapper = styled.div`
  position: relative;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SankeyDiagram = () => {
  const svgRef = useRef();
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  useEffect(() => {
    // Function to update dimensions based on the viewport size
    const updateDimensions = () => {
      setDimensions({
        width: window.innerWidth * 0.4,
        height: window.innerHeight * 0.5,
      });
    };

    updateDimensions();

    window.addEventListener('resize', updateDimensions);

    return () => window.removeEventListener('resize', updateDimensions);
  }, []);

  useEffect(() => {
    if (!dimensions.width || !dimensions.height) return; // Guard clause

    const data = JSON.parse(JSON.stringify(sankeyData));
    const maxValue = d3.max(data.links, d => d.value);
    const minValue = d3.min(data.links, d => d.value);
    const linkWidthScale = d3.scaleLinear()
    .domain([minValue, maxValue])
    .range([1, 20]); // Example: Minimum width of 1px, maximum of 20px



    data.links.forEach(link => {
      link.source = data.nodes.find(node => node.id === link.source);
      link.target = data.nodes.find(node => node.id === link.target);
      if (!link.source || !link.target) {
        console.error('Link source or target not found:', link);
      }
    });

    const color = d3.scaleOrdinal(d3.schemeCategory10);

    const sankeyGenerator = sankey()
      .nodeWidth(36)
      .nodePadding(40)
      .extent([[0, 5], [dimensions.width - 1, dimensions.height - 5]]);

    const { nodes, links } = sankeyGenerator(data);

    nodes.forEach((node, index) => {
      node.group = index % 5;
    });

    const svg = d3.select(svgRef.current)
      .attr('viewBox', `0 0 ${dimensions.width} ${dimensions.height}`)
      .attr('style', `width: 100%; height: 100%`);

    svg.selectAll('*').remove();

    const link = svg.append('g')
    .selectAll('path')
    .data(links)
    .join('path')
      .attr('d', sankeyLinkHorizontal())
      .attr('stroke', d => color(d.source.group))
      .attr('stroke-opacity', 0.5)
      .attr('fill', 'none')
      .attr('stroke-width', d => linkWidthScale(d.value));
    
    link.append("title")
      .text(d => `${d.source.name || d.source.id} → ${d.target.name || d.target.id}\nValue: ${d.value}`);
  

    const nodeRects = svg.append('g')
      .selectAll('rect')
      .data(nodes)
      .join('rect')
        .attr('x', d => d.x0)
        .attr('y', d => d.y0)
        .attr('height', d => Math.max(0, d.y1 - d.y0))
        .attr('width', sankeyGenerator.nodeWidth())
        .attr('fill', d => color(d.group))
        .attr('stroke', 'black');

    nodeRects.append('title').text(d => `${d.id}\n${d.value}`);

    svg.append('g')
    .attr("font-family", "sans-serif")
    .attr("font-size", 10)
    .selectAll('text')
    .data(nodes)
    .join('text')
      .attr('x', d => (d.x0 + d.x1) / 2)
      .attr('y', d => (d.y0 + d.y1) / 2)
      .attr('dy', '0.35em')
      .attr('text-anchor', 'middle')
      .text(d => d.id);

  }, [dimensions]);

  return (
    <SankeyDiagramWrapper>
      <svg ref={svgRef}></svg>
    </SankeyDiagramWrapper>
  );
};

export default SankeyDiagram;
