import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const MainContainer = styled.div`
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const SubContainer = styled.div`
  margin-top: 10vh;
  margin-bottom: 10vh;
  margin-left: 10vw;
  margin-right: 10vw;
  width: 80vw;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
`;

const PartyButton = styled.button`
  margin: 5px;
  padding: 2vh 2vw;
  border: none;
  border-radius: 1vh;
  background-color: black;
  color: white;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s;
  flex: 1 0 calc(25% - 10px);

  &:hover {
    background-color: grey;
  }
`;

const ToggleSwitch = styled.label`
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  margin: 20px 0;

  & input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  & .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: black;
    transition: .4s;
    border-radius: 34px;
  }

  & .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: .4s;
    border-radius: 50%;
  }

  input:checked + .slider {
    background-color: black;
  }

  input:checked + .slider:before {
    transform: translateX(26px);
  }

  & .label {
    position: absolute;
    color: black;
    font-size: 14px;
    font-weight: bold;
    top: 50%;
    transform: translateY(-50%);
  }

  & .label.left {
    left: -80px;
  }

  & .label.right {
    right: -80px;
  }
`;

const ToggleLabel = styled.span`
  font-size: clamp(1rem, 6vw + 0.5rem, 2vh);
`;



function SelectionPanel() {
    const [items, setItems] = useState([]);
    const [toggleState, setToggleState] = useState('partidos');

    useEffect(() => {
        const initialParties = [
            "ADN", "B.E.", "CDS-PP", "E", "IL", "JPP", "L", "MAS", "MPT", "PAN",
            "PCP/PEV", "MRPP", "R.I.R.", "VP", "CH", "PPD/PSD", "PTP", "A",
            "NC", "PPM"
        ];

        const initialRegions = [
            "Aveiro", "Açores", "Beja", "Braga", "Bragança", "Castelo Branco",
            "Coimbra", "Europa", "Faro", "Fora da Europa", "Guarda", "Leiria",
            "Lisboa", "Madeira", "Portalegre", "Porto", "Santarém",
            "Setúbal", "Viana do Castelo", "Vila Real", "Viseu", "Évora"
        ];

        const shuffleArray = array => {
            for (let i = array.length - 1; i > 0; i--) {
                const j = Math.floor(Math.random() * (i + 1));
                [array[i], array[j]] = [array[j], array[i]];
            }
        };

        if (toggleState === 'partidos') {
            const shuffledParties = [...initialParties];
            shuffleArray(shuffledParties);
            setItems(shuffledParties);
        } else {
            const shuffledRegions = [...initialRegions];
            shuffleArray(shuffledRegions);
            setItems(shuffledRegions);
        }
    }, [toggleState]);

    return (
        <MainContainer>
            <ToggleSwitch>
                <ToggleLabel className="label left">Partidos</ToggleLabel>
                <input type="checkbox" onChange={() => setToggleState(toggleState === 'partidos' ? 'circulos' : 'partidos')} checked={toggleState !== 'partidos'} />
                <span className="slider"></span>
                <ToggleLabel className="label right">Círculos</ToggleLabel>
            </ToggleSwitch>
            <SubContainer>
                {items.map((item, index) => (
                    <PartyButton key={index}>{item}</PartyButton>
                ))}
            </SubContainer>
        </MainContainer>
    );
}

export default SelectionPanel;
