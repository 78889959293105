import './App.css';
import VotoDesperdicado from "./pages/VotoDesperdicado";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

function App() {
  return (
    <div>
      <Router>
        <div className="App">
          <Routes>
            <Route path="/" element={<VotoDesperdicado/>} />
          </Routes>
        </div>
      </Router>
    </div>
  );
}

export default App;
