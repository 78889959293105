import React from "react";
import styled from 'styled-components';
import PageTitle from "../components/PageSEO";
import Presentation from "./Presentation";
import Simulator from "./Simulator";
import SankeyChart from "./SankeyChart";
import SelectionPanel from "./SelectionPanel";


const LandingPageComponent = styled.div`
    width: 100vw;
    display: flex;
    flex-direction: column;
    font-size: clamp(0.5rem, 2vw + 0.5rem, 1.7rem);
    font-family: Space Mono, monospace;
`;

function VotoDesperdicado() {      
    return (
        <LandingPageComponent>
            <PageTitle
                title="Voto Desperdicado"
                description="Simulador voto desperdicado."
                canonical="/voto-desperdicado"
            />
            <Presentation/>
            <SelectionPanel/>
            <Simulator/>
            <SankeyChart/>
        </LandingPageComponent>
    );
}

export default VotoDesperdicado;
