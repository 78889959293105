import React from 'react';
import styled from 'styled-components';
import {useState, useEffect} from 'react'
import data from '../data/simulatorDataset.json';

const SimulatorStyle = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100vw;
    height: 100vh;
`;

const Form = styled.form`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 6vh;
`;

const Select = styled.select`
    width: 50vw;
    padding: 2vh 2vw;
    border-radius: 1vh;
    border: 1px solid #ccc;
    background-color: ${props => props.disabled ? '#eee' : 'white'};
    color: ${props => props.disabled ? '#999' : 'black'};
    font-size: clamp(0.5rem, 2vw + 0.5rem, 1rem);
    cursor: pointer;
`;

const Button = styled.button`
    padding: 2vh 4vw;
    cursor: pointer;
    background-color: black;
    border: none;
    border-radius: 1vh;
    color: white;
    font-size: clamp(0.5rem, 2vw + 0.5rem, 1rem);
    &:hover {
        background-color: grey;
    }
`;

function Simulator() {
    const [showResults, setShowResults] = useState(false);
    const [selectedDistrict, setSelectedDistrict] = useState('');
    const [parties, setParties] = useState([]);
    const [selectedParty, setSelectedParty] = useState('');
    const [resultMessage, setResultMessage] = useState('');

    useEffect(() => {
        if (selectedDistrict) {
            setParties(Object.keys(data[selectedDistrict] || {}));
        }
    }, [selectedDistrict]);

    const handleDistrictChange = (e) => {
        setSelectedDistrict(e.target.value);
        setSelectedParty(''); // Reset party selection when district changes
    };

    const handlePartyChange = (e) => {
        setSelectedParty(e.target.value);
    };

    const handleReset = () => {
        setShowResults(false);
        setSelectedDistrict('');
        setSelectedParty('');
        setParties([]);
        setResultMessage('');
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const partyValue = data[selectedDistrict][selectedParty];
        const message = partyValue > 0 ? 'Voto representado' : 'Voto desperdiçado'; // Determine the message based on party value
        setResultMessage(message);
        setShowResults(true);
    };

    return (
        <SimulatorStyle>
            {!showResults ? (
                <Form onSubmit={handleSubmit}>
                    <Select name="district" id="district" value={selectedDistrict} onChange={handleDistrictChange}>
                        <option value="">Select District</option>
                        {Object.keys(data).map((district) => (
                            <option key={district} value={district}>{district}</option>
                        ))}
                    </Select>
                    <Select name="party" id="party" value={selectedParty} onChange={handlePartyChange} disabled={!selectedDistrict}>
                        <option value="">Select Party</option>
                        {parties.map((party) => (
                            <option key={party} value={party}>{party}</option>
                        ))}
                    </Select>
                    <Button type="submit" disabled={!selectedDistrict || !selectedParty}>Verifique o Voto</Button>
                </Form>
            ) : (
                <Form>
                    <p>{resultMessage}</p>
                    <Button type="button" onClick={handleReset}>Try Again</Button>
                </Form>
            )}
        </SimulatorStyle>
    );
}

export default Simulator;